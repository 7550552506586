@mixin clearfix {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin icon-font {
  font-family: 'iconInventory', Arial, Helvetica;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
