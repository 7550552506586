@import "../../../assets/styles/base";

.alert_main__header {
  background: #FFFFFF;
  height: 53px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}

.alert_main__header__left-side {
  display: flex;
  gap: 9px;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.alert_main__header__close__icon {
  color: #737779;

  &:hover {
    cursor: pointer;
  }

  svg {
    path {
      fill: #737779;
    }
  }
}

.alert_main__header__title {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #3A3F4D;
  padding-left: 0;
}

.alert_main__header__close {
  flex: 1;
  padding-right: 0;
  text-align: right;
}

.alert_main__content {
  color: #737779;
  padding: 15px 30px 30px 30px;

  a {
    text-decoration: none;
  }
}

.alert_main__footer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  flex-wrap: nowrap;
  padding-right: 23px;
  padding-bottom: 20px;

  .btn-kit.MuiButtonBase-root {
    margin-bottom: 0;
  }
}

.alert_main__button {
  .btn-kit {
    border: 0;
    &:hover {
      border: 0;
    }
  }
  &--slow {
    .btn-kit {
      background: rgba(37, 182, 191, 0.1);
      border-color: transparent;
      span {
        color: #00AA80;
      }
    }
    &.warning {
      .btn-kit {
        background: #FFF5F0;
        span {
          color: #E25822;
        }
      }
    }
  }

  &--hard {
    span {color: #FFFFFF;}
    .btn-kit {
      background: #00AA80;
      border-color: transparent;
      span {
        color: #ffffff;
      }
    }
    &.warning {
      .btn-kit {
        background: #E25822;
        span {
          color: #FFFFFF;
          border-color: transparent;
        }
      }
    }
  }
}

@media (min-width: $lg) {
  .alert_main__header__close {
    flex: 1;
    text-align: right;
  }
}