@import "../../../assets/styles/variables";

.toolbar-kit__main {
  .MuiAppBar-colorPrimary {
    background-color: $white;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.06);
    .MuiContainer-root {
        height: 72px;
    }
  }
}

.menu_items__container {
  a {
    text-decoration: none;
  }
}

.toolbar__main__content {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-content: center;
  flex-wrap: nowrap;
  align-items: center;

  &.MuiToolbar-regular {
    height: 100%;
    padding-left: 15px;
  }

  svg {
    fill: $dark-gray;
  }

  .BaseBadge-root {
    .icon-button {
        &.MuiIconButton-root {
            background: $white;
            box-shadow: inherit;
            border-radius: 10px;
            width: 48px;
            height: 48px;
        }
    }
  }
  .MuiBadge-badge {
      color: $main;
      background-color: $main;
      box-shadow: 0 0 0 1px $main;
      &:after {
      position: 'absolute';
      top: 0;
      left: 0;
      width: '100%';
      height: '100%';
      border-radius: '50%';
      animation: 'ripple 1.2s infinite ease-in-out';
      border: '1px solid currentColor';
      content: '';
      }

      .MuiButtonBase-root {
          border-radius: 50%;
      }
  }
  @keyframes ripple {
    0% {
      transform: 'scale(.8)';
      opacity: 1;
    }
    100% {
      transform: 'scale(1)';
      opacity: 0;
    }
  }
}

.toolbar__profile__content {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 16px;

    p {
        color: $dark-gray;
    }
    .MuiButtonBase-root {
        &.MuiButton-text {
            padding: 0;
            min-width: 24px;
        }
    }
}

.drawer-kit__main {
  .MuiDrawer-paper {
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.06);
    height: 100%;
    width: 250px;
    background: linear-gradient(219.93deg, #00AA80 12.52%, #25B6BF 136.91%);
    z-index: 39;
  }
}

.header-kit_drawer {
  height: auto;
  margin-top: 70px;
  margin-bottom: 40px;
}

.header-kit_drawer__profile {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  height: 100%;
}

.header-kit_drawer__profile-content {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}

.toolbar-kit__main__content__img {
  margin-right: 15px;
}

.list-kit-main {
  .list-kit-main__item {
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
    cursor: pointer;
    height: 51px;
    width: 90%;

    &--active,
    &.MuiListItem-button:hover {
        background-color: $white;
        .list-kit-main__item--text span,
        span {
            color: $main;
        }
        * {
            fill: $main;
        }
    }
    a {
      text-decoration: none;
    }
  }

  .list-kit-main__item--icon {
    color: $white;
    min-width: 35px;
  }
  .list-kit-main__item--text {
    
    span {
      color: $white;
      font-family: 'Montserrat', sans-serif;
      font-size: 16px;
      font-weight: $weight-bold;
      line-height: 24px;
    }
  }
}

.footer-kit_drawer {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  height: 53px;
  color: $white;
  cursor: pointer;

  .MuiSvgIcon-root {
    font-size: 30px;
    margin-left: 18px;
    margin-right: 8px;
  }
  .MuiListItemIcon-root {
    min-width: 33px;
  }

  .list-kit-main__item--text {
    span {
      font-family: "Montserrat", sans-serif;
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
  }
}

.footer_drawer__text {
  font-weight: $weight-bold;
  font-size: 16px;
  line-height: 20px;
}

@media (min-width: $md) {
  .toolbar__main__content {
    &.MuiToolbar-regular {
      padding-left: 0px;
    }
  }
}

.profile-menu {
    .MuiPaper-root {
        .MuiList-root {
            .MuiButtonBase-root {
                &:hover {
                    background-color: $main;
                    color: $white;
                }
            }
        }
    }
    .profile-menu__container {
        @apply my-3;
        color: #3A3F4D;
     .profile-menu__content {
        @apply text-sm mx-4 pb-2;
        border-bottom: 1px solid rgba(141, 152, 157, 0.2);
        .profile-menu__name {
            @apply mb-1;
        }
        .profile-menu__email {
            color: #8D989D;
        }
     }
     .profile-menu__items {
        .profile-menu__option {
            @apply cursor-pointer px-4 py-2;
            &:first-child {
                &:hover {
                    background-color: #F8F8FB;
                }
            }
            &:nth-child(2) {
                &:hover {
                    color: $aquamarine;
                    text-decoration: underline;
                }
            }
        }
     } 
    }
}