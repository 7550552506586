// colors
$background-color: #F4F4F4;
$black: #000000;
$border-gray:#BDBDBD;
$green: #00AA80;
$yellow: #FAD980;
$light-green: #2CAD6E;
$light-blue: #25B6BF;
$dark: #3A3F4D;
$dark-gray: #8D989D;
$medium-gray: #BCC7D1;
$light-gray: #E8EDF0;
$white: #ffffff;
$light-white: #ffffff;
$orange: #FF6666;
$light-space-green: #33CC99;
$aquamarine:#25B6BF;
$overlay-brown: hsla(0, 0%, 0%, 0.75);
$overlay-light-gray: hsla(224, 14%, 26%, 0.5);
$overlay-dark-gray: hsla(0, 0%, 0%, 0.4);
$overlay-light-blue: hsla(209, 73%, 56%, 0.5);

$hyperlink: #007A8C;
$main: $green;

// fonts weights
$weight-extrabold: 600;
$weight-bold: 500;
$weight-medium: 400;
$weight-light: 300;

// breakpoints
$sm: 320px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;