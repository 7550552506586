.selected-actions__main_st {
  @apply w-full h-16 bg-white rounded-t-md;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  flex-wrap: nowrap;
  gap: 26px;
  padding-left: 70px;

  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0.75rem;
  border-top-left-radius: 0.75rem;
  border: 3px solid #f4f4f4;
  border-bottom: 1px solid rgba(#DFDFDF, 0.4);

  .selected__field {
    width: 200px !important;

    .MuiAutocomplete-inputRoot {
      height: 35px !important;
      padding: 0 9px !important;
    }
  }
  .selected-text {
    @apply font-regular text-sm leading-4;
    letter-spacing: 0.05em;
    color: #687182;

    &--number {
      @apply font-semibold;
    }
  }
}