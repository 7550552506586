@import "../../../assets/styles/variables";

$disabled-gray: #D0D5DD;

.btn {
  background: linear-gradient(179.94deg, $main 0.05%, $main 113.22%);
  border-radius: 24px;
  cursor: pointer;
  padding: 10px 0;
  width: 124px;

  &.MuiButton-contained {
    &:hover {
      background: linear-gradient(219.93deg, rgba(0, 170, 128, 0.8) 12.52%, rgba(37, 182, 191, 0.8) 136.91%);
      .MuiButton-label {
          color: $white;
      }
      &.MuiButton-outlined {
          border: 1px solid $white;
      }
      svg {
          color: $white;
      }
    }
    &.btn--disabled {
      background: rgba(0, 170, 128, 0.1);
      color: #ABC2D4;
      box-shadow: 0px 1px 4px rgba(52, 60, 68, 0.1);
    
      .MuiButton-label {
          color: $disabled-gray;
      }
      .MuiButton-label {
          color: $disabled-gray;
      }
      svg {
          color: $disabled-gray;
      }
    }
  }
  &:hover {
    background: linear-gradient(219.93deg, rgba(0, 170, 128, 0.8) 12.52%, rgba(37, 182, 191, 0.8) 136.91%);
    .MuiButton-label {
        color: $white;
    }
    &.MuiButton-outlined {
        border: 1px solid $white;
    }
    svg {
        color: $white;
    }
  }
  &:focus {
    background: #25B6BF;
    .MuiButton-label {
        color: $white;
    }
  }
  &.transparent-btn {
    background: rgba(189, 189, 189, 0.5);
    /* Boton */

    border: 2px solid #00AA80;
    box-sizing: border-box;
    border-radius: 10px;
  }

  &.MuiButtonBase-root {
    margin-bottom: 13px;
    text-transform: capitalize;
    height: 40px;
    width: auto;
    
    &.btn--from-modal {
      border-radius: 0;
    }

    &.btn--circular {
        border-radius: 24px;
    }
  
    &.btn--square {
        border-radius: 8px;
    }
  }

  span {
    color: $light-white;
    font-style: normal;
    font-weight: $weight-bold;
    font-size: 16px;
    line-height: 20px;
  }

  &--disabled {
    cursor: default;
    background-color: #ABC2D4;
    color: #ABC2D4;
    border-radius: 10px;
    
    .MuiButton-label {
      color: $disabled-gray;
    }
    .MuiButton-label {
        color: $disabled-gray;
    }
    svg {
        color: $disabled-gray;
    }
  }

  &--blue {
    background: linear-gradient(to right, $light-blue, $light-blue);
  }

  a {
    text-decoration: none;
  }

  &--small {
    span {
      font-size: 12px;
    }
    
    .MuiButton-label {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
    }
  }

  &--outlined {
    background: linear-gradient(179.94deg, $white 0.05%, $white 113.22%);
    transition: 1s all ease-in-out;

    .MuiButton-label {
        color: $main;
    }
    &.MuiButton-outlined {
        border-color: $main;
    }
    svg {
        color: $main;
    }

    &:hover {
        background: rgba(37, 182, 191, 0.1);
        .MuiButton-label {
            color: $main;
        }
        
        &.MuiButton-outlined {
            border-color: $main;
        }

        svg {
            color: $main;
        }
    }
    &:focus {
        background: linear-gradient(179.94deg, $white 0.05%, $white 113.22%);
        
        .MuiButton-label {
            color: $main;
        }
        &.MuiButton-outlined {
            border-color: $main;
        }
        svg {
            color: $main;
        }
    }

    &.btn--disabled {
      background-color: #ABC2D4;
      color: #ABC2D4;
      box-shadow: 0px 1px 4px rgba(52, 60, 68, 0.1);

      .MuiButton-label {
          color: $disabled-gray;
      }
      .MuiButton-label {
          color: $disabled-gray;
      }
      svg {
          color: $disabled-gray;
      }
    }
  }
}