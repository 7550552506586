@import "../../../assets/styles/variables";

.time-picker__main {
    .MuiFormControl-root {
        @apply w-full;

        .MuiInputBase-root {
            fieldset {
                border: 1px solid rgba(37, 182, 191, 0.1);
                box-shadow: 0px 1px 4px rgb(52 60 68 / 10%);
                border-radius: 8px;
            }
            &.Mui-focused, &:hover {
                fieldset {
                    border-color: $main;
                }
            }
        }
    }   
}

.MuiClockPicker-root {
    .MuiButtonBase-root {
        @apply absolute p-1 rounded;
    }
    .MuiClockPicker-arrowSwitcher {
        .MuiButtonBase-root {
            @apply relative;
        }   
    }
}