
@import "../../../assets/styles/variables";

.tasks_actions__main {
    .tab-panel {
        .MuiContainer-root {
            @apply px-0;
        }
        .table__main {
            @apply rounded-t-none rounded-b-xl;
            border: 3px solid #f4f4f4;
        }
    }
}

.extra__link {
    @apply flex justify-end flex-row flex-nowrap items-center my-6 mr-5;
}

.general__form__main {
    .textfield__main {
        &.link__field {
            svg {
                fill: $white;
            }
        }
    }
}

.inscribed__main {
    .MuiButton-root {
        width: 220px;
        &.MuiButton-outlined {
            border-width: 2px;
        }
    }
}
.turns__main {
    .turns-options {
        @apply flex gap-5;
    }
    .turns-options-one {
        .btn {
            width: 170px;
        }
    }
}
.materials__main {
    .materials__tile {
        @apply pb-5 font-bold;
    }
    .accordion__main {
        .MuiAccordion-root {
            .MuiAccordionSummary-root {
                &.MuiButtonBase-root {
                    padding-left: 10px;
                    background-color: #f4f4f4;
                    flex-direction: row-reverse;
                    gap: 12px;
                }
            }
            .MuiCollapse-root {
                @apply px-6 pt-3 pb-6;

                &.MuiCollapse-hidden {
                    @apply p-0;
                }
            }
            .select__main {
                .checkboxes__list {
                    .MuiFormControl-root {
                        .MuiFormGroup-root {
                            display: grid;
                            grid-template-columns: 1fr 1fr;
                            grid-template-rows: repeat(auto-fit, minmax(50px, 1fr));
                            .MuiFormControlLabel-root {
                                @apply flex-row justify-start gap-2;
                                .MuiTypography-root {
                                    line-height: 24px;
                                }
                            }
                        }
                    }
                }
                .select__button {
                    @apply flex flex-row flex-nowrap content-center justify-start items-center w-auto;

                    color: $aquamarine;
                    gap: 5px;

                    &:hover {
                        @apply underline cursor-pointer;
                    }
                }
                .select__options {
                    @apply flex gap-5 relative pb-3;
                    border-bottom: 1px solid rgba(0, 170, 128, 0.1);

                    .outfit-title__main {
                        @apply flex flex-row flex-nowrap items-center justify-start content-center w-full mt-4;

                        .outfit-title {
                            @apply flex-1;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }
                    .filter-button {
                        @apply w-12 h-10;
                    }
                    .filter-menu {
                        @apply hidden bg-white rounded-lg p-2;

                        .label-filter-menu-item {
                            @apply flex justify-start items-center px-2 rounded-t-lg;
                            background-color: #FBFBFB;
                            border-bottom: 1px solid rgba(0, 170, 128, 0.1);
                            height: 50px;
                        }

                        .filter-menu-item {
                            @apply px-2;
                            .textfield__label {
                                color: $black;
                            }
                            .MuiFormControl-root {
                                @apply w-full;
                                .multiple-select {
                                    .MuiSelect-select {
                                        padding: 0.5938rem 2rem 0.5938rem 0.875rem;
                                    }
                                }
                            }
                        }
                        .filter-footer {
                            @apply flex flex-row justify-end items-center content-center gap-5;
                            padding-right: 10px;
                            height: 50px;

                            .MuiButtonBase-root {
                                width: 66px;
                                height: 22px;
                                margin-bottom: 0;
                                span {
                                    @apply text-sm;
                                }
                            }
                        }
                        .search__main {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            align-content: center;
                            flex-wrap: nowrap;
                            flex-direction: row;
                            gap: 5px;
                            padding: 6px 10px;
                            margin-bottom: 5px;
                            background-color: #FBFBFB;

                            .MuiInputBase-root {
                                &:before,
                                &:after,
                                &:hover {
                                    border: none
                                }
                            }
                        }
                        &--open {
                            @apply block absolute top-14 overflow-x-auto;

                            z-index: 2;
                            min-width: 270px;
                            max-height: 74vh;

                            /* width */
                            &::-webkit-scrollbar {
                                width: 7px;
                                border-radius: 20px;
                            }

                            /* Track */
                            &::-webkit-scrollbar-track {
                                @apply bg-white;
                            }

                            /* Handle */
                            &::-webkit-scrollbar-thumb {
                                background: #B5B7BA;
                                border-radius: 20px;
                            }

                            /* Handle on hover */
                            &::-webkit-scrollbar-thumb:hover {
                                background: #555;
                            }
                        }
                        &--no-handle {
                            @apply top-2;
                            .filter-menu-item {
                                @apply p-0;
                                .accordion__main .MuiAccordion-root .MuiAccordionSummary-root {
                                    padding-left: 5px;
                                }
                            }
                        }
                    }
                    .multiple-select {
                        @apply bg-white;
                    }
                }
            }
        }
    }
}
.extra__buttons__main {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    align-content: center;
    flex-wrap: nowrap;
    gap: 20px;
    margin-right: 20px;
    margin-top: 40px;

    .MuiContainer-root {
        width: 125px;
        margin: 0;
    }

    .general-buttons__main {
        display: flex;
        gap: 16px;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
    }
}
.certifications__main {
}
.table__main {
    .hyperlink-row {
        @apply cursor-pointer no-underline;
        color: $hyperlink;
        &:hover {
            @apply underline;
            color: $aquamarine;
        }
    }
    .circle-signed-contract {
        background-color: #FBFBFB;
        border-radius: 50px;
        width: 30px;
        height: 30px;

        display: flex;
        align-items: center;
        justify-content: center;
        align-content: center;
        flex-wrap: nowrap;
        flex-direction: row;

        svg {
            height: 15px;
            width: 15px;
        }
    }
    .checkin-row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        gap: 10px;

        span {
            @apply cursor-pointer;

            &:hover {
              svg {
                path {
                    fill: $aquamarine;
                }
              }
            }
        }
    }
}
.turns__modal__main {
    &.modal__main {
        @apply w-full;
    }
    .modal_main__content {
        @apply p-0;
        .turns-form__main {
            @apply px-6;
            .input-number__main {
                @apply flex flex-col items-start;
                .textfield__main {
                    @apply w-full;
                    .MuiInputBase-root {
                        @apply w-full;
                    }
                }
            }
            .select__button {
                @apply flex flex-row flex-nowrap content-start justify-end items-center w-auto;

                color: $aquamarine;
                gap: 5px;

                &:hover {
                    @apply underline cursor-pointer;
                }
            }
        }
        .create-multi-turns__main {
            @apply p-6;
            min-width: 320px;
            .extra__buttons__main {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                align-content: center;
                flex-wrap: nowrap;
                margin-right: 0;

                .MuiContainer-root {
                    width: auto;
                    .btn {
                        width: auto;
                        margin: 0;

                        svg {
                            fill: $white;
                        }
                    }
                }
            }
        }
    }
}

.view__task__turn__input > div > input {
    height: 36px !important;
}

.custom__height__general__form__view__task > div > input {
    height: 55px !important;
}

.custom__height__general__form__view__task {
    padding-left: 9px !important;
    padding-right: 9px !important;
}

.disabled__icon__attendance_table > svg {
    cursor: not-allowed;
    opacity: 0.6;
}

.disabled__icon__attendance_table > svg {
    cursor: not-allowed;
}

.disabled__icon__attendance_table:hover > svg > path {
    fill: #3A3F4D !important;
}

.modal__attendance__photo .modal_main__content {
    padding: 1rem;
}

.modal__main.modal__attendance__photo {
    width: 600px;
    max-width: 95vw;
}

.attendance__photo__modal__container {
    width: 100%;
    position: relative;
    text-align: center;
}

.attendance__photo__modal__container img {
    width: 100%;
    border-radius: .375rem;
}

.attendance__photo__subtitle {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    background: linear-gradient(219.93deg, rgba(0, 170, 128, 0.8) 12.52%, rgba(37, 182, 191, 0.8) 136.91%);
    border-radius: 5px;
    font-size: 14px;
    padding: 8px 8px;
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
}

.attendance__photo__subtitle.show {
    opacity: 1;
}

.modal__attendance__map .modal_main__content {
    padding: 1rem;
}

.modal__main.modal__attendance__map {
    width: 600px;
    max-width: 95vw;
}

.attendance__map__modal__container {
    width: 100%;
    border: 1px solid lightgray;
    border-radius: .375rem;
}

.attendance__map__modal__container > div {
    border-radius: .375rem;
}
