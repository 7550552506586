@import "../../../assets/styles/variables";

.file-upload__main {
    @apply cursor-pointer;
    .with-textfield__main {
        .MuiContainer-root {
            margin-bottom: 0;
            flex: 1;
            .btn {
                margin-bottom: 0;
            }
        }
    }
}

.image-upload {
    width: 110px;
    height: 110px;
    background: linear-gradient(219.93deg, $main 12.52%, #25B6BF 136.91%);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  
    svg {
      cursor: pointer;
    }
    input {
      display: none;
    }
    img {
      border-radius: 8px;
      cursor: pointer;
      object-fit: cover;
      height: 110px;
      width: 110px;

      &:hover {
        opacity: 0.6;
      }
    }

    &:hover {
      background: linear-gradient(219.93deg, rgba(0, 170, 128, 0.8) 12.52%, rgba(37, 182, 191, 0.8) 136.91%);
    }
}

.with-textfield__main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 34px;
    padding-right: 34px;
    align-content: center;

    .textfield__section {
        position: relative;

        .excel-icon__main {
            position: absolute;
            right: 20px;
            top: -10px;
            background-color: #0c0c0c00;
            svg {
                height: 22px;
                width: 22px;
            }
        }
    }

    .text-field__upload {
        display: none;
    }

    .text-field__label {
        width: 100%;
        height: 66px;
        background-color: #FBFBFB;
        border: 1px solid rgba(37, 182, 191, 0.1);
        border-radius: 8px;
        color: $dark-gray;
        padding: 0 25px;
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
        justify-content: flex-start;
    }

    .MuiContainer-root {
        margin-bottom: 0;
    }
}