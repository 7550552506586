@import "../../../assets/styles/base";

.modal_main__header {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 53px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}

.modal_main__header__close__icon {
  color: $main;

  &:hover {
    cursor: pointer;
  }
}

.modal_main__header__title {
  color: $aquamarine;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-left: 20px;
}

.modal_main__header__close {
  flex: 1;
  padding-right: 20px;
  text-align: right;
}

.modal_main__content {
  padding: 30px 0;

  a {
    text-decoration: none;
  }
}

.modal_main__footer {
  position: absolute;
  bottom: 0;
  width: 100%;

  .btn.MuiButtonBase-root {
    margin-bottom: 0;
  }
}

@media (min-width: $lg) {
  .modal_main__header__title {
    margin-left: 50px;
  }

  .modal_main__header__close {
    flex: 1;
    padding-right: 50px;
    text-align: right;
  }
}