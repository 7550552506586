@import "../../../assets/styles/variables";

.modal__main {
  @media (min-width: $xl) {
    width: 40%;
  }

  &--kit {
    @media (min-width: $xl) {
      width: 70%;
      max-width: 1000px;
    }
  }
}

.modal_main__header {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 53px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}

.modal_main__header__close__icon {
  color: $main;

  &:hover {
    cursor: pointer;
  }
}

.modal_main__header__close {
    flex: 1;
    padding-right: 20px;
    text-align: right;
  }

.modal_main__header__title {
  color: $aquamarine;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-left: 20px;

  @media (min-width: $lg) {
    margin-left: 25px;
  }
}

.modal_main__content {
  padding: 30px 0;
  max-height: 76vh;
  a {
    text-decoration: none;
  }
}

.modal_main__footer {
  position: absolute;
  bottom: 0;
  width: 100%;

  .btn.MuiButtonBase-root {
    margin-bottom: 0;
  }
}

.modal_main__header__close.loading__basic__modal {
  opacity: 0.5;
}