@import "../../../assets/styles/variables";

.title {
  color: $aquamarine;
  font-style: normal;
  font-weight: $weight-extrabold;
  font-size: 24px;
  line-height: 32px;
  text-align: start;
  &--secondary {
    font-weight: $weight-bold;
  }
  &--disabled {
    color: $medium-gray;
  }
}