
@import "../../../assets/styles/variables";

.tasker-selected-options {
    @apply flex flex-row justify-between items-center content-center;
    .left-options {
        .go-back-main {
            @apply flex flex-row justify-start items-center content-center gap-2 cursor-pointer;

            span {
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
            }
            &:hover {
                span {
                    @apply underline;
                }
            }
        }
    }
    .right-options {

    }
}
.tasker-selected-avatar {
    @apply mt-9 mb-10 flex flex-row items-center content-center justify-start gap-6;
    .avatar-image {
        @apply w-10 h-10;
        img {
            @apply rounded-md w-10 h-10 object-cover;
        }
    }
    .avatar-name {
        @apply text-2xl font-semibold;
    }
}
.margin_input{
    margin: 10px;
}