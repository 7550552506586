@import "../../../assets/styles/variables";


.accordion__main {
    .MuiPaper-root {
        &.MuiPaper-elevation {
            @apply rounded-none m-0;

            .MuiButtonBase-root {
                display: flex;
                padding: 0;
            }
        }
        &:before {
            height: 0;
        }
    }
    .MuiAccordionSummary-root {
        svg {
            fill: $main;
        }
    }
    .MuiAccordionSummary-content {
        .accordion-label {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 0.05em;
            color: $dark;
        }
        &.Mui-expanded {
            .accordion-label {
                font-weight: 600;
            }
        }
    }
    .MuiCollapse-root {
        border-bottom: 1px solid rgba(0, 170, 128, 0.1);
        .MuiAccordionDetails-root {
            padding: 0;
        }
        .MuiFormControl-root {
            @apply w-full;

            .MuiFormControlLabel-root {
                height: 50px;
                display: flex;
                flex-direction: row-reverse;
                flex-wrap: nowrap;
                align-content: center;
                justify-content: space-between;
                align-items: center;
                margin: 0;
            }
            .MuiTypography-root {
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                letter-spacing: 0.05em;
                color: $dark;

            }
            .MuiSvgIcon-root {
                fill: $main;
            }
        }
    }
    &--plus {
        .MuiPaper-root {
            &.MuiPaper-elevation {
                @apply mb-10;
            }
        }
        .MuiAccordionSummary-root {
            border-bottom: 2px solid #E0E0E0;
            svg {
                fill: #000000;
            }
        }
    }
    &--no-border {
        .MuiCollapse-root {
            border-bottom: 0;
        }
    }
}

