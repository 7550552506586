@import "../../../assets/styles/variables";

.error_message__main {
    text-align: start;
}

.error_message {
    color: $main;
    font-weight: $weight-bold;
    font-size: 14px;
    line-height: 17px;

    &--white {
        color: $white;
        text-align: start;
    }
}