@import "../../../assets/styles/variables";

.MuiFormControlLabel-root {
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-wrap: nowrap;
  align-items: center;
  
  &.MuiFormControlLabel-labelPlacementStart {
    margin: 0;
  }
  .MuiTypography-root {
    color: $aquamarine;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
}
.switch__main {
  &.MuiSwitch-root {
    height: 34px;
    padding: 0;
    border-radius: 50px;
  }

  .MuiSwitch-switchBase {
    margin: 2px 1px 1px 1px;
    padding: 0;
    transform: translateX(4px);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background-color: transparent;
    outline: 0;
    border: 0;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    text-decoration: none;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    color: #fff;
    transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    &.Mui-checked {
      background-color: $main;
      color: #fff;
      transform: translateX(22px);
      .MuiSwitch-thumb {
        &:before {
          background-image: url(../../../assets/images/check.svg);
          background-size: 15px;
        }
      }
      & + .MuiSwitch-track {
        opacity: 1;
        background-color: $main;
      }
    }
    .MuiSwitch-thumb {
      background-color: $white;
      width: 30px;
      height: 30px;
      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url(../../../assets/images/cancel.svg);
        background-size: 15px;
      }
    }
  }

}