@import "../../../assets/styles/variables";

.title__main {
    margin-bottom: 45px;
}
.select__style{
  padding: 2.5px 9px !important;
 
}

.create__users__main {
    @apply bg-white rounded-lg;
    border: 1px solid #EFF0F7;
    box-shadow: 0px 1px 4px rgba(52, 60, 68, 0.1);
    padding: 34px;

    .stepper__create__users {
        border-bottom: 1px solid #D9DBE9;
        padding-bottom: 34px;

        .stepper__content {
            @apply w-2/4 m-auto;
        }
    }
    .create__users__template {
        @apply text-center;
        margin-top: 62px;
        margin-bottom: 60px;

        h2 {
            @apply text-2xl font-semibold;
            margin-bottom: 5px;
        }
    }
}

.form__main {
  &--data {
    .fields-group {
       .field-item {
         .textfield__label {
           color: $black;
         }
         .textfield__main {
           .MuiInputBase-root {
             input {
               padding: 2.5px 9px !important;
             }
             .MuiAutocomplete-input {
               padding: 2px 4px 7.5px 6px;
             }
           }
           svg {
             fill: $black;
           }
         }
         .MuiInputAdornment-root {
           .MuiSvgIcon-root {
             fill: $black;
           }  
         }
       }
    }
  }
   &--tasks {
       .MuiFormControl-root {
            @apply w-full;
       }
   }
}

.create__users__btn__main {
    @apply flex items-center justify-between mt-8;
    
    .go_back__main {
      @apply flex items-end justify-start;
      .link__main {
          text-decoration: none;

          svg {
            width: 16px;
            height: 16px;
            margin-bottom: 3px;
          }
      }
    }
    .actions__btn__main {
      @apply flex items-center justify-between flex-nowrap flex-row gap-4 w-full;

      .left__part {
        @apply flex items-center justify-start flex-grow-0 flex-row gap-4;
        .MuiButtonBase-root {
            @apply mb-0;
        }
      }

      .right__part {
        .MuiButtonBase-root {
          @apply mb-0 w-auto;
        }
      }
    }
    .MuiContainer-root {
        @apply w-auto m-0 p-0;
    }
}

.modules__main {
    table {
        border-collapse: separate;
        border-spacing: 10px 0;
        margin: auto;
        tr {
            th {
                margin-right: 11px;
                background: #FBFBFB;
                border-bottom: 2px solid #F5F5F5;
                color: $dark;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                height: 44px;
                width: 85px;
            }
            .enabled__modules__title {
                background: $white;
                color: #25B6BF;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                padding-left: 16px;
                text-align: start;
                width: 200px;
            }

            td {
                border-bottom: 2px solid #F5F5F5;
                height: 40px;
                &.item__modules__title {
                    background: #FBFBFB;
                    font-weight: 600;
                    padding-left: 16px;
                    text-align: start;
                }
                .block {
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    margin: auto;
                    
                    &--green {
                        background: linear-gradient(219.93deg, rgba(0, 170, 128, 0.8) 12.52%, rgba(37, 182, 191, 0.8) 136.91%);
                    }
                    &--gray {
                        background-color: #F5F5F5;
                    }
                }
            }
        }
    }
}