@import "../../../assets/styles/variables";

$tooltip_go_main_color: #0C0A02;

.tooltip___go.MuiTooltip-popper {

    .MuiTooltip-tooltipArrow {
        background-color: $tooltip_go_main_color;
        //height: 40px;
        height: auto;
        padding: 10px;
        border-radius: 8px;

        &.MuiTooltip-tooltip, * {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
        }
        .MuiTooltip-arrow {
            color: $tooltip_go_main_color;
        }
    }
}