@import "../../../assets/styles/variables";

.import-schedule__main {
    .MuiContainer-root {
        margin: 0;
        margin-bottom: 16px;
        max-width: 100%;
    }
    .import-schedule-title {
        color: $light-blue;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
    }
    
    .import-schedule-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        a {
            font-weight: 600;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

.cursor-pointer-link {
    cursor: pointer;
}