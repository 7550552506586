@import "../../../assets/styles/variables";

.input-number__main {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-end;
    align-items: center;
    
    p {
        color: $light-blue;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        margin-bottom: 2px;
        text-align: start;
    }

    .MuiFormControl-root {
        width: 51px;

        .MuiInputBase-root {
            width: 51px;
        }
    }
}