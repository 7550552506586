@import "../../../assets/styles/variables";

.doc-item__main {
    background-color: #FBFBFB;
    border: 1px solid rgba(37, 182, 191, 0.1);
    box-sizing: border-box;
    border-radius: 8px;
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0;
    padding: 0 16px 0 20px;
}

.doc-item__icon {
    width: 50px;
    
    svg {
        margin: auto;
    }
}
.doc-item__text {
    flex: 1;
    min-width: 150px;
    padding-left: 16px;
}
.doc-item__options {
    svg {
        fill: $main;
    }
}