@import "../../../assets/styles/variables";

.MuiTooltip-popper {
    
    .MuiTooltip-tooltipArrow {
        background-color: $light-blue;
        height: 40px;
        padding: 10px;
        border-radius: 8px;

        &.MuiTooltip-tooltip, * {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
        }
        .MuiTooltip-arrow {
            color: $light-blue;
        }
    }
}