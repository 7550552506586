@import "../../../assets/styles/variables";

.link__main {
  color: $main;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  text-align: start;
  text-decoration: underline;
  &--disabled {
    color: $medium-gray;
  }
  &:hover {
    text-decoration: none;
  }
}