@import "../../../assets/styles/variables";

.textfield__large {
  display: flex;
  flex-direction: column;
}

.textfield__label {
  color: $aquamarine;
  font-size: 16px;
  font-weight: $weight-medium;
  line-height: 28px;
  margin-bottom: 2px;
  text-align: start;
}

.textfield__main {
  width: 100%;

  .MuiInputBase-root {
    background-color: $white;
    
    .MuiInputAdornment-root {
      .MuiSvgIcon-root {
        fill: $border-gray
      }
    }

    input { 
      padding: 12px 9px;
      height: 12px;

      &:-internal-autofill-selected {
        background-color: $white;
      }
    }
    .MuiOutlinedInput-notchedOutline {
        border: 0;
        border: 1px solid rgba(37, 182, 191, 0.1);
        box-shadow: 0px 1px 4px rgba(52, 60, 68, 0.1);
        border-radius: 8px;
    }
    &.Mui-disabled {
      background-color: #FBFBFB;
    }
  }

  &.auth__field {
    input {
      color: #929292;
    }
  }
  &.password__field {
    input {
      letter-spacing: 12px;
    }
  }
}