@import "../../../assets/styles/variables";

.breadcrumb__container {
    .MuiBreadcrumbs-separator {
        margin-left: 6px;
        margin-right: 6px;
    }
    &--primary {
        li {
            a, .MuiTypography-root {
                font-family: 'Montserrat', sans-serif;
                color: $dark-gray;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                margin-top: 1px;
            }
        }
    }
    &--secondary {
        li {
            a, .MuiTypography-root {
                font-family: 'Montserrat', sans-serif;
                color: $dark-gray;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
            }
        }
        .MuiBreadcrumbs-separator {
            margin-left: 4px;
            margin-right: 4px;
        }
    }
    &--third {
        a, .MuiTypography-root {
            font-family: 'Montserrat', sans-serif;
            color: #222834;
            font-weight: 400;
            font-size: 14px;
            line-height: 32px;
        }
        .MuiBreadcrumbs-separator {
            margin-left: 4px;
            margin-right: 4px;
        }
    }

    .link {
        &:hover {
            text-decoration: underline;
        }
    }
}