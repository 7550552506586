@import "../../../assets/styles/variables";

.text__main {
  color: $dark-gray;
  font-size: 16px;
  line-height: 20px;
  text-align: start;
  &--disabled {
    color: $medium-gray;
  }
}