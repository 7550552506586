@import "../../../assets/styles/variables";

.autocomplete__main {
  &.MuiAutocomplete-root fieldset:hover{
    border-color: $main;
  }
  .MuiIconButton-label {
    color: $main;
  }
}
.textfield__label {
  color: $aquamarine;
  font-size: 14px;
  font-weight: $weight-bold;
  line-height: 17px;
  margin-bottom: 2px;
  text-align: start;
}


.textfield__main {
  width: 100%;

  .MuiOutlinedInput-root {
    &.MuiAutocomplete-inputRoot {
      height: 60px;
    }
  }
  .MuiOutlinedInput-root:hover {
    .MuiOutlinedInput-notchedOutline {
      border-color: $main;
    }
  }
  input { 
    padding: 12px 9px;
  }
  svg {
    fill: $main;
  }
}

.textfield__main.inner__input > div > input {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}