@import "../../../assets/styles/variables";

.loader__container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    background-color: $white;
    transform: translate(-50%, -50%);
    z-index: 10;
}

.loader__main {
    @apply fixed;
    .MuiCircularProgress-root {
        @apply fixed;
        left: calc(50% - 50px);
        top:50%;
    }
}