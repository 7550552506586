@import "../../../assets/styles/variables";

.multiple-select  {
    max-width: 552px;
    .MuiSelect-select {
        @apply text-left;
        display: flex;
        align-items: center;
    }
}

.multiselect-item {
    height: 50px;
    &.MuiMenuItem-root {
        @apply flex-row-reverse;
    }
    
    .MuiListItemText-root {
        max-width: 292px;
        span {
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}