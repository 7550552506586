@import "../../../assets/styles/variables";
.app__main {
    background-color: $background-color;
}

.app__content {
    min-height: calc(100vh - 70px);
    margin-left: 0;
    padding-top: 14vh;
    padding-bottom: 50px;
    
    @media (min-width: $md) {
        margin-left: 240px;
    }

    .MuiButton-root {
        color: rgba(58, 63, 77, 1);
    }

    &--kit {
        padding-top: 8vh;
        padding-bottom: 50px;
    
        @media (min-width: $md) {
            margin-left: 240px;
        }

        @media (min-width: 1800px) {
            margin-left: 0;
        }
    }
}