@import "../../../assets/styles/variables";

.loader-local__container {
    width: 100%;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;
    z-index: 10;
}

.loader-local__main {
    @apply fixed;
    .MuiCircularProgress-root {
        @apply fixed;
    }
}