@import "../../../assets/styles/variables";

.MuiStepper-root {
    .MuiStep-root {
        .MuiStepLabel-iconContainer {
            .step__icon {
                @apply flex justify-center items-center content-center flex-nowrap flex-row;
                width: 34px;
                height: 34px;
                background: #EFF0F6;
                border-radius: 50%;
                color: #6F6C90;
            }
        }
        .MuiStepConnector-line {
            top: 16px;
            border: 4px solid #EFF0F6;
            border-radius: 40px;
            width: 90%;
            margin: auto;
        }
        &.Mui-completed {
            .MuiStepLabel-root {
                .MuiStepLabel-iconContainer {
                    .step__icon {
                        @apply flex justify-center items-center content-center flex-nowrap flex-row;
                        width: 34px;
                        height: 34px;
                        background: #25B6BF;
                        border-radius: 50%;
                        color: $white;
                    }
                }
            }
            .MuiStepConnector-root {
                .MuiStepConnector-line {
                    top: 16px;
                    border: 4px solid #25B6BF;
                    border-radius: 40px;
                    width: 90%;
                    margin: auto;
                }
            }
            
        }

    }
}